<template>
   <Layout>
      <PageHeader :title="title" :items="items"></PageHeader>
      <div class="row">
         <div class="col-12">
            <div class="card">
               <div class="card-body">
                  <b-form enctype="multipart/form-data" ref="immediateAction">
                     <div class="row">
                        <b-form-group class="col-6">
                           <label for="input-1">Action Name <span style="color: red">*</span></label>
                           <b-form-input id="input-1" v-model="form.action" placeholder="Enter Action Name" :class="{
                              'is-invalid': submitted && $v.form.action.$error,
                           }">
                           </b-form-input>
                           <div v-if="submitted && !$v.form.action.required" class="invalid-feedback">
                              Action Name is required.
                           </div>
                        </b-form-group>
                        <b-form-group class="col-6">
                           <label for="input-2">Action ID <span style="color: red">*</span></label>
                           <b-form-input id="input-2" v-model="form.action_id" placeholder="Enter Action ID"
                              :class="{ 'is-invalid': submitted && $v.form.action_id.$error }">
                           </b-form-input>
                           <div v-if="submitted && !$v.form.action_id.required" class="invalid-feedback">Action ID is
                              required.</div>
                        </b-form-group>
                        <b-form-group class="col-12">
                           <label for="input-3">Description <span style="color: red">*</span></label>
                           <b-form-textarea id="input-3" v-model="form.value" placeholder="Enter something..." rows="3"
                              :class="{ 'is-invalid': submitted && $v.form.value.$error }"></b-form-textarea>
                           <div v-if="submitted && !$v.form.value.required" class="invalid-feedback">Enter Unique Description</div>
                        </b-form-group>
                        <b-form-group class="col-6">
                           <label for="input-5">BG Color <span style="color: red">*</span></label>
                           <b-form-input type="color" id="input-5" class="w-100" v-model="form.bg_color"
                              :class="{ 'is-invalid': submitted && $v.form.bg_color.$error }" />
                           <div v-if="submitted && !$v.form.bg_color.required" class="invalid-feedback">BG Color is
                              required.</div>
                        </b-form-group>
                        <b-form-group class="col-6" label="Image" label-for="input-6">
                           <b-form-file id="image_name" accept="image/*" placeholder="Choose a file or drop it here..."
                              @change="readFile($event, 'image_name')" ref="image_name">
                           </b-form-file>
                           <template v-if="$route.name == 'edit-immediate-action' && edit.image_name_url">
                              <img :src="edit.image_name_url" width="128px" height="128px"
                                 style="object-fit: contain; margin-top: 5px" />
                           </template>
                           <template v-if="image_name_url">
                              <img :src="image_name_url" width="128px" height="128px" ref="image_name_url"
                                 style="object-fit: contain; margin-top: 5px" />
                           </template>
                        </b-form-group>
                        <b-form-group class="col-6">
                           <b-form-checkbox v-model="form.status">Status</b-form-checkbox>
                        </b-form-group>
                     </div>
                     <b-button type="submit" variant="primary" class="mr-2" @click.prevent="submitData"><span
                           v-if="this.$route.name == 'add-immediate-action'">Save Data</span>
                        <span v-else>Update Data</span>
                     </b-button>
                  </b-form>
               </div>
            </div>
         </div>
      </div>
   </Layout>
</template>
 
<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import MixinRequest from "../../../mixins/request";
import immediateAction from "../../../mixins/ModuleJs/immediate-action";
import { required } from "vuelidate/lib/validators";

export default {
   components: {
      Layout,
      PageHeader,
   },
   mixins: [MixinRequest, immediateAction],
   data() {
      return {
         submitted: false,
         title: "Add Immediate Action",
         items: [
            {
               text: "Back",
               href: "/immediate-action",
            },
            {
               text: "Data",
            },
         ],
      };
   },
   validations: {
      form: {
         action: { required },
         action_id: { required },
         bg_color: { required },
         value: { required }
      }
   }
};
</script>
 